<template>
  <el-dialog
    :close-on-click-modal="false"
    class="small-padding"
    :title="title"
    :visible.sync="dialogVisible"
    width="1250px"
    top="2vh"
    @close="dialogReset">
    <div v-loading="contentLoading">
      <el-form
        ref="formValidate"
        class="form"
        :label-width="$l('role.labelWidth','100px')"
        :model="role"
        :rules="ruleValidate">
        <div class="vm-separate">
          <el-form-item :label="$t('role.name')" prop="name">
            <el-input v-model="role.name" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$l('role.nameEn','英文名称')" prop="nameEn">
            <el-input v-model="role.nameEn" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item v-if="role.id === 0" :label="$t('role.isDefault')">
            <el-radio-group v-model="role.isDefault">
              <el-radio :label="1">{{$t("common.yes")}}</el-radio>
              <el-radio :label="0">{{$t("common.no")}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-form>
      <div class="permissionWrapper">
        <div class="permissionTitle">{{$t("role.auth")}}</div>
        <el-checkbox-group v-model="role.indexHave" class="permission">
          <el-collapse>
            <el-collapse-item v-for="permission in permissions" :key="permission.name">
              <template slot="title">
                <i class="header-icon el-icon-info" style="margin-left: 10px"></i>
                <span style="margin-left: 5px">{{permission.name}}</span>
              </template>
              <el-checkbox
                v-for="child in permission.children"
                :key="child.index"
                :label="child.index"
                style="margin-left: 30px">
                <div v-if="$i18n.isCn" style="width: 200px;" :title="child.name">
                  {{child.name.length > 15 ? child.name.substring(0, 15) + "..." : child.name}}
                </div>
                <div v-else style="width: 450px;" :title="child.name">
                  {{child.name.length > 50 ? child.name.substring(0, 50) + "..." : child.name}}
                </div>
              </el-checkbox>
            </el-collapse-item>
          </el-collapse>
        </el-checkbox-group>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" @click="handleSubmit">{{$t("common.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  const moduleName = "roles";
  const moduleName_permissions = "permissions";

  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        permissions: [],
        role: {
          id: 0,
          name: "",
          nameEn: "",
          isDefault: 0,
          indexHave: [],
        },
        ruleValidate: {
          name: [
            {required: true, message: this.$t("role.tip.name"), trigger: "blur"},
          ],
        },
      };
    },
    computed: {
      title() {
        return (this.role.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("role.role");
      },
    },
    mounted() {
    },
    methods: {
      open(id) {
        this.role.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        this.contentLoading = true;
        this.$api.getList(moduleName_permissions).then(res => {
          this.permissions = res.data;
          if (this.role.id !== 0) { // 编辑页面需要获取role
            this.getRole();
          } else {
            this.contentLoading = false;
          }
        });
      },
      getRole() {
        this.$api.getById(moduleName, this.role.id).then(res => {
          this.contentLoading = false;
          this.role = res.data;
        });
      },
      dialogReset() {
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save(moduleName, this.role).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.role.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.permissionWrapper {
  position: relative;
  height: calc(100vh - 290px);
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #e6ebf5;

  .permissionTitle {
    padding: 5px 10px;
    font-size: 16px;
    height: 30px;
    font-weight: bold;
    line-height: 30px;
  }

  .permission {
    padding: 10px 0;
    position: absolute; //使用absolute可以让页面更流畅
    top: 30px;
    right: 0;
    bottom: 0;
    left: 0
  }
}
</style>
